@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400;500;700&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "swipe/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "swipe/vendor";

// Pixel mixins & functions
@import "swipe/mixins";
@import "swipe/functions";

// Utilities
@import "swipe/reboot";
@import "swipe/utilities";

// Layout
@import "swipe/layout";

// Components
@import "swipe/components";

// write your custom styles here!